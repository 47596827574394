<template>
  <div class="game-container">
    <MagnifierGame :optionList="optionList" :bgImg="bgImg" :lessonNo="53" :title="title"/>
  </div>
</template>

<script>
import MagnifierGame from "@/components/Course/GamePage/MagnifierGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-53/book-background-park.svg"),
      optionList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-53/bench.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-53/fountain.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-53/lamp.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-53/swing.svg"),
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-53/trashcan.svg"),
        },
         {
          id: 6,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-53/trees.svg"),
        },
      ],
      title:{
        pinyin:"Gōngyuán lǐ yǒu shénme?",
        hanzi:"公园里有什么？"
      }
    };
  },
  components: {
    MagnifierGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











