<template>
  <div class="recognition-page-Two">
    <RecognitionPage :backgroundImage="bgImg_hao" :wordObj="wordDetail_hao" />
  </div>
</template>

<script>
import RecognitionPage from "@/components/Course/CoursePage/RecognitionPage";
export default {
  data() {
    return {
      bgImg_hao: require("@/assets/img/12-Vocabulary/lesson-53-renshi.svg"),
      wordDetail_hao: {
        pinName: "rènshi",
        chineseName: "认识",
        englishName: "to know",
        songUrl: require("@/assets/audio/L1/5-Words/renshi.mp3"),

      },
    };
  },
  components: {
    RecognitionPage,
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-Two {
  width: 100%;
  height: 100%;
}
</style>