<template>
  <div class="game-container">
    <MatchTheCardsGame :imgList="imgList" @changeCardBG="changeCardBG" :titleInfo="titleInfo"/>
  </div>
</template>

<script>
import MatchTheCardsGame from "@/components/Course/GamePage/MatchTheCardsGame";
export default {
  data() {
    return {
      titleInfo:{
          pinyin:"Tāmen zài nǎr?",
          hanzi:"他们在哪儿？",
          padding: 20,
          scale: 0.8
      },
      imgList: [
         {
          id: 3,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-53/girl.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-53/boy.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-53/couple.svg"),
          type: 0,
          cardActive: false,
        },
         {
          id: 3,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-53/girl-place.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-53/couple-place.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-53/boy-place.svg"),
          type: 0,
          cardActive: false,
        },
      
      ],
    };
  },
  components: {
    MatchTheCardsGame,
  },
  methods: {
    changeCardBG(index, firstIndex, type) {
      this.imgList[index].type = type;
      this.imgList[index].cardActive = false;
      this.imgList[firstIndex].type = type;
      this.imgList[firstIndex].cardActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











