<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :isPageA="true" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      wordListData: [
        {
          title: "我会认",
          isUse: false,
          content: [
            {
              pinyin: "gāoxìng",
              hanzi: "高兴",
              symbol: "",
              group: 0,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 1,
              symbolType: 1,
            },
            {
              pinyin: "rènshi",
              hanzi: "认识",
              symbol: "",
              group: 0,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 3,
              symbolType: 1,
            },
            {
              pinyin: "gōngyuán",
              hanzi: "公园",
              symbol: "",
              group: 0,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 5,
              symbolType: 1,
            },
            {
              pinyin: "duō",
              hanzi: "多",
              symbol: "",
              group: 0,
              index: 6,
              symbolType: 0,
            },
           
            //、、和、姐姐、弟弟

            // symbolType: 0word 1、 2： 3 “” 4！ 5…… 6。
          ],
        },
        {
          title: "我会用",
          isUse: true,
          contentList: [
            [
              {
                pinyin: " zhīshídiǎn",
                hanzi: "知识点",
                symbol: "",
                group: 1,
                line: 1,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "jù zǐ",
                hanzi: "句子",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "“",
                group: 1,
                line: 1,
                index: 2,
                symbolType: 3,
              },
              {
                pinyin: "hěn",
                hanzi: "很",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "gāo",
                hanzi: "高",
                symbol: "",
                group: 1,
                line: 1,
                index: 4,
                symbolType: 0,
              },
              {
                pinyin: "xìng",
                hanzi: "兴",
                symbol: "",
                group: 1,
                line: 1,
                index: 5,
                symbolType: 0,
              },
              {
                pinyin: "rèn",
                hanzi: "认",
                symbol: "",
                group: 1,
                line: 1,
                index: 5,
                symbolType: 0,
              },
              {
                pinyin: "shí",
                hanzi: "识",
                symbol: "",
                group: 1,
                line: 1,
                index: 5,
                symbolType: 0,
              },
              {
                pinyin: "nǐ",
                hanzi: "你",
                symbol: "",
                group: 1,
                line: 1,
                index: 5,
                symbolType: 0,
              },
             
              {
                pinyin: "",
                name: "",
                symbol: "。",
                group: 1,
                line: 1,
                index: 8,
                symbolType: 6,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "”",
                group: 1,
                line: 1,
                index: 9,
                symbolType: 7,
              },
              {
                pinyin: "de",
                hanzi: "的",
                symbol: "",
                group: 1,
                line: 1,
                index: 5,
                symbolType: 0,
              },
              {
                pinyin: "shǐyòng qíng jǐng",
                hanzi: "使用情景",
                symbol: "",
                group: 1,
                line: 1,
                index: 5,
                symbolType: 0,
              },
            ],
           
          ],
        },
        {
          title: "我会说",
          isUse: false,
          content: [
            // {
            //   pinyin: "nǐ",
            //   hanzi: "你",
            //   symbol: "",
            //   id: 1,
            //   symbolType: 0,
            // },

            {
              pinyin: "yòng",
              hanzi: "用",
              symbol: "",
              group: 2,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "Zhōngwén",
              hanzi: "中文",
              symbol: "",
              group: 2,
              index: 1,
              symbolType: 0,
            },

            {
              pinyin: "hé ",
              hanzi: "和",
              symbol: "",
              group: 2,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "chū cì",
              hanzi: "初次",
              symbol: "",
              group: 2,
              index: 3,
              symbolType: 0,
            },
            {
              pinyin: "jiàn miàn",
              hanzi: "见面",
              symbol: "",
              group: 2,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "de",
              hanzi: "的",
              symbol: "",
              group: 2,
              index: 5,
              symbolType: 0,
            },
            {
              pinyin: "rén",
              hanzi: "人",
              symbol: "",
              group: 2,
              index: 6,
              symbolType: 0,
            },
            {
              pinyin: "dǎ zhāo hū",
              hanzi: "打招呼",
              symbol: "",
              group: 2,
              index: 7,
              symbolType: 0,
            },
          ],
        },
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>