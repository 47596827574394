<template>
  <div class="game-container">
    <RecognitionPinyinGame :optionList="optionList" :imgList="imgList" :isFullScreen="true"/>
  </div>
</template>

<script>
import RecognitionPinyinGame from "@/components/Course/GamePage/RecognitionPinyinGame";
export default {
  data() {
    return {
      optionList: [
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-53/image-5.svg"),
        },
          {
          id: 1,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-53/image-4.svg"),
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-53/image-1-gaoxing.svg"),
        },
        
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-53/image-2.svg"),
        },
        
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-53/image-3.svg"),
        },
         {
          id: 1,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-53/image-1.svg"),
        },
         {
          id: 2,
          image: require("@/assets/img/16-GAMES/G42-choose-ab/lesson-53/image-1-bu-gaoxing.svg"),
        },
      ],

      imgList: [
        {
          id: 1,
          pinying: "gāoxìng",
          hanzi: "高兴",
        },
        {
          id: 2,
          pinying: "bù gāoxìng",
          hanzi: "不高兴",
        },
      ],
      // titleInfo:{
      //   pinyin:"qù nǎr？",
      //   hanzi:"……去哪儿？"
      // }
    };
  },
  components: {
    RecognitionPinyinGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











